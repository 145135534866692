import "@/styles/globals.scss";
import * as countries from "i18n-iso-countries";
import * as de from "i18n-iso-countries/langs/de.json";
import * as en from "i18n-iso-countries/langs/en.json";
import { appWithTranslation, useTranslation } from "next-i18next";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { z } from "zod";
import { makeZodI18nMap } from "zod-i18n-map";
import {start} from "@/bugsnag";

start();

interface AppPropsWithErr extends AppProps {
  err: any;
}

function App({ Component, pageProps, err }: AppPropsWithErr) {
  const { pathname } = useRouter();
  const queryClient = new QueryClient();
  countries.registerLocale(de);
  countries.registerLocale(en);
  // setupI18n();
  const { t } = useTranslation();
  z.setErrorMap(makeZodI18nMap({ t }));
  useEffect(() => {
    if (pathname.startsWith("/admin")) {
      const body = document.querySelector("body");
      if (body) {
        body.style.cssText = "background-color: #f6f6f6;";
      }
    }
  });
  return (
    <>
      <Head>
        <meta charSet="utf-8"/>
        <meta httpEquiv="content-language" content="de"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <meta
            name="description"
            content="Alle Informationen und Hintergrundberichte zur besten Liga Europas."
            key="description"
        />
        <meta name="owner" content=""/>
        <meta name="revisit-after" content="2days"/>
        <meta
            name="og:title"
            content="Tischtennis Bundesliga - TTBL"
            key="og:title"
        />
        <meta
            name="og:description"
            content="Alle Informationen und Hintergrundberichte zur besten Liga Europas."
            key="og:description"
        />
        {/* TODO: Dynamic base url */}
        <meta
            name="og:image"
            content={`https://www.ttbl-staging.de/tischtennis_bundesliga_logo.png`}
            key="og:image"
        />
        <meta name="og:url" content="https://ttbl.de/"/>
        <meta name="og:type" content="website"/>
        <meta
            name="twitter:title"
            content="Tischtennis Bundesliga - TTBL"
            key="twitter:title"
        />
        <meta
            name="twitter:description"
            content="Alle Informationen und Hintergrundberichte zur besten Liga Europas."
            key="twitter:description"
        />
        <meta
            name="twitter:image"
            content="https://www.ttbl-staging.de/tischtennis_bundesliga_logo.png"
            key="twitter:image"
        />
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:site" content="@TTBuLi"/>

        {/* Google tag (gtag.js) */}
        <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-WT0170VGM7"
        ></script>
        <script
            dangerouslySetInnerHTML={{
              __html: `window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-WT0170VGM7');`,
            }}
        />
      </Head>
      <QueryClientProvider client={queryClient}>
        <Component {...pageProps} err={err}/>
      </QueryClientProvider>
    </>
  );
}

export default appWithTranslation(App);
